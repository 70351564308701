const env = import.meta.env.VITE_PUBLIC_APP_STAGE
export const exampleFeatureFlag = `epic_123_story_456_${env}`

// Create Unique IDs on all application and enrollment packet questions options
export const EPIC_1582_TASK_2739 = `epic-1582-task-2739-${env}`
export const EPIC_1582_STORY_4466 = `release-epic-1582-story-4466-${env}`

// MARK Feature Flags
export const EPIC_1765_STORY_1865 = `epic_1765_story_1865_${env}`
export const EPIC_4225_BUG_4520 = `release-epic-4225-bug-4520-${env}`

// User Enhancements
export const EPIC_275_STORY_5773 = `release-epic-275-story-5773-${env}`
export const EPIC_275_STORY_5395 = `release-epic-275-story-5395-${env}`
export const EPIC_275_BUG_2046 = `release-epic-275-bug-2046-${env}`
export const EPIC_275_BUG_2047 = `release-epic-275-bug-2047-${env}`
export const EPIC_275_BUG_5556 = `release-epic-275-bug-5556-${env}`
export const EPIC_275_STORY_5565 = `release-epic-275-story-5565-${env}`
export const EPIC_275_BUG_5777 = `release-epic-275-task-5777-${env}`
export const EPIC_275_STORY_5776 = `release-epic-275-story-5776-${env}`
export const EPIC_275_STORY_5775 = `release-epic-275-story-5775-${env}`
export const EPIC_275_TASK_5778 = `release-epic-275-task-5778-${env}`
export const EPIC_275_BUG_2172 = `release-epic-275-bug-2172-${env}`
export const EPIC_275_STORY_5772 = `release-epic-275-story-5772-${env}`
export const EPIC_275_STORY_5812 = `release-epic-275-story-5812-${env}`
export const EPIC_275_STORY_5774 = `release-epic-275-story-5774-${env}`
export const EPIC_275_STORY_5779 = `release-epic-275-story-5779-${env}`
export const EPIC_275_BUG_6034 = `release-epic-275-bug-6034-${env}`

//Teacher communication announcements
export const EPIC_1450 = `release-epic-1450-teacher-communication-announcements-${env}`
export const EPIC_1450_STORY_1456 = `epic_1450_story_1456_${env}`
export const EPIC_1450_STORY_1458 = `epic_1450_story_1458_${env}`
export const EPIC_1450_STORY_1457 = `epic_1450_story_1457_${env}`
export const EPIC_1450_STORY_1462 = `epic_1450_story_1462_${env}`
export const EPIC_1450_TASK_5422 = `release-epic-1450-task-5422-${env}`
export const KILL_SWITCH_INTERCOM = `kill-switch-intercom-${env}`

//2nd semester schedules
export const EPIC_1847_BUG_4293 = `release-epic-1847-bug-4293-${env}`
export const EPIC_1847_BUG_3930 = `release-epic-1847-bug-3930-${env}`

//Testing Preference
export const EPIC_3863_BUG_4427 = `release-epic-3863-bug-4427-${env}`
export const EPIC_3863_BUG_2999 = `release-epic-3863-bug-2999-${env}`
export const EPIC_3346_BUG_5434 = `release-epic-3346-bug-5434-${env}`

// Admin Gradebook > detailed report of Learning Logs
export const KILL_SWITCH_LL_DETAILED_REPORT = `kill-switch-job-reportforlearninglogfiles-${env}`
export const EPIC_3725_BUG_5466 = `release-epic-3725-bug-5466-${env}`
export const EPIC_3725_BUG_3440 = `release-epic-3725-bug-3440-${env}`
export const EPIC_5462_BUG_5493 = `release-epic-5462-bug-5493-${env}`

export const EPIC_4659_BUG_4976 = `release-epic-4659-bug-4976-${env}`

export const EPIC_1582_BUG_4854 = `release-epic-1582-bug-4854-${env}`

export const EPIC_3176_STORY_3472 = `release-epic-3176-bug-3472-${env}`

export const EPIC_3489_STORY_3490 = `release-epic-3489-story-3490-${env}`
export const EPIC_3489_STORY_4859 = `release-epic-3489-story-4859-${env}`

export const EPIC_549_STORY_3389 = `release-epic-549-bug-3389-${env}`

export const EPIC_1847_BUG_4313 = `release-epic-1847-bug-4313-${env}`

export const EPIC_3733_BUG_4439 = `release-epic-3733-bug-4439-${env}`

export const EPIC_3660_BUG_2691 = `release-epic-3660-bug-2691-${env}`

//Homeroom Enhancements
export const EPIC_3662_STORY_4818 = `release-epic-3662-story-4818-${env}`

//maintenance mode
export const OPERATIONAL_MAINTENANCE_SCREEN = `operational-maintenance-screen-${env}`
export const OPERATIONAL_FORCE_REFRESH = `operational-Force-Frontend-Refresh-${env}`
export const OPERATIONAL_INTERVENTION_STATUS_DROPDOWN = `operational-intervention-status-dropdown-${env}`

//Intent to Re-enroll
export const EPIC_3733_STORY_4142 = `release-epic-3733-story-4142-${env}`
export const EPIC_3733_BUG_4599 = `release-epic-3733-bug-4599-${env}`
export const EPIC_3733_BUG_4762 = `release-epic-3733-bug-4762-${env}`
export const EPIC_3733_STORY_4792 = `release-epic-3733-story-4792-${env}`
export const EPIC_3733_BUG_4987 = `release-epic-3733-bug-4987-${env}`

export const EPIC_3579_STORY_1428 = `release-epic-3579-story-1428-${env}`

//Enrollment Packet
export const EPIC_3537_BUG_2116 = `release-epic-3537-bug-2116-${env}`
export const EPIC_3537_BUG_4622 = `release-epic-3537-bug-4622-${env}`
export const EPIC_3537_STORY_5147 = `release-epic-3537-story-5147-${env}`

// email uniqueness
export const EPIC_3537_STORY_4552 = `release-epic-3537-story-4552-${env}`

// pre-kindergarten
export const EPIC_4318_STORY_4482 = `release-epic-4318-story-4482-${env}`
export const EPIC_4659_STORY_4896 = `release-epic-4659-story-4896-${env}`

// Reports Enhancements
export const EPIC_1766_STORY_5524 = `release-epic-1766-story-5524-${env}`
export const EPIC_1766_STORY_4857 = `release-epic-1766-story-4857-${env}`
export const EPIC_1766_BUG_5460 = `release-epic-1766-bug-5460-${env}`
export const EPIC_1766_STORY_5850 = `release-epic-1766-story-5850-${env}`
export const EPIC_1766_STORY_2986 = `release-epic-1766-story-2986-${env}`
export const EPIC_1766_STORY_5957 = `release-epic-1766-story-5957-${env}`

// Email Templates Enhancements
export const EPIC_3864_STORY_4500 = `release-epic-3864-story-4500-${env}`
export const EPIC_3864_STORY_5796 = `release-epic-3864-story-5796-${env}`

//12th grade transition
export const EPIC_4601_STORY_4856 = `release-epic-4601-story-4856-${env}`

//Unique Identifier
export const EPIC_3489_STORY_3581 = `release-epic-3489-story-3581-${env}`

//Course catalog
export const EPIC_3664_STORY_1805 = `release-epic-3664-bug-1805-${env}`

// Microservice: Async Downloads
export const EPIC_4309_STORY_4339 = `release-epic-4309-story-4339-${env}`

export const EPIC_3698_BUG_4932 = `release-epic-3698-bug-4932-${env}`

//Schedule builder
export const EPIC_3666_BUG_1938 = `release-epic-3666-bug-1938-${env}`
export const EPIC_3666_BUG_1963 = `release-epic-3666-bug-1963-${env}`
export const EPIC_2966_BUG_5383 = `release-epic-2966-bug-5383-${env}`
export const EPIC_3322_BUG_3933 = `release-epic-3322-bug-3933-${env}`

//Mid-year enrollment enhancements
export const EPIC_3804_BUG_3616 = `release-epic-3804-bug-3616-${env}`
export const EPIC_3804_BUG_3967 = `release-epic-3804-bug-3967-${env}`
export const EPIC_3804_BUG_3823 = `release-epic-3804-bug-3823-${env}`
export const EPIC_3804_BUG_5815 = `release-epic-3804-bug-5815-${env}`
export const EPIC_3804_BUG_5657 = `release-epic-3804-bug-5657-${env}`
export const EPIC_3804_BUG_3453 = `release-epic-3804-bug-3453-${env}`

//Announcement Popups
export const EPIC_4594 = `release-epic-4594-AnnouncementPopups-${env}`

export const EPIC_4594_STORY_4663 = `release-epic-4594-story-4663-${env}`
// Homerrom resources
export const EPIC_121_STORY_5623 = `release-epic-121-story-5623-${env}`

export const EPIC_3665_STORY_5127 = `release-epic-3665-story-5127-${env}`

export const EPIC_3176_story_3685 = `release-epic-3176-story-3685-${env}`

// RR / DO / DD
export const EPIC_4247_TASK_6065 = `release-epic-4247-task-6065-${env}`
export const EPIC_4861_TASK_5321 = `release-epic-4861-task-5321-${env}`
export const EPIC_4861_TASK_5320 = `release-epic-4861-task-5320-${env}`
export const EPIC_4861_STORY_2881 = `release-epic-4861-story-2881-${env}`
export const EPIC_4861_STORY_5222 = `release-epic-4861-story-5222-${env}`
export const EPIC_4861_STORY_3675 = `release-epic-4861-story-3675-${env}`
export const EPIC_4861_STORY_3430 = `release-epic-4861-story-3430-${env}`
export const EPIC_4861_STORY_5577 = `release-epic-4861-story-5577-${env}`
export const EPIC_4861_STORY_5880 = `release-epic-4861-story-5880-${env}`
export const EPIC_4861_STORY_6073 = `release-epic-4861-story-6073-${env}`

// RR / DO / DD Bugs
export const EPIC_5134_BUG_5472 = `release-epic-5134-bug-5472-${env}`
export const EPIC_5134_BUG_5687 = `release-epic-5134-bug-5687-${env}`
export const EPIC_5134_BUG_3556 = `release-epic-5134-bug-3556-${env}`
export const EPIC_5134_BUG_3507 = `release-epic-5134-bug-3507-${env}`

//24-25 RR / DO / DD Enhancements
export const EPIC_4247_STORY_6016 = `release-epic-4247-story-6016-${env}`

export const EPIC_3660_STORY_4182 = `release-epic-3660-story-4182-${env}`

// School of Enrollment
export const EPIC_2209_STORY_3779 = `release-epic-2209-story-3779-${env}`
export const EPIC_2209_STORY_3780 = `release-epic-2209-story-3780-${env}`
export const EPIC_2209_STORY_5188 = `release-epic-2209-story-5188-${env}`

//Learning log edit
export const EPIC_3662_STORY_3189 = `release-epic-3662-story-3189-${env}`

export const EPIC_2966_BUG_4694 = `release-epic-2966-bug-4694-${env}`
export const EPIC_2966_TASK_4164 = `release-epic-2966-task-4164-${env}`

//Teacher - learning logs
export const EPIC_1450_BUG_5487 = `release-epic-1450-bug-5487-${env}`
export const EPIC_4162_STORY_5552 = `release-epic-4162-story-5552-${env}`

// Collapse side bar
export const EPIC_3681_STORY_5237 = `release-epic-3861-story-5237-${env}`
export const EPIC_3861_STORY_5377 = `release-epic-3861-story-5377-${env}`
export const EPIC_3861_STORY_5516 = `release-epic-3861-story-5516-${env}`
export const EPIC_3861_STORY_5614 = `release-epic-3861-story-5614-${env}`
export const EPIC_3861_STORY_5613 = `release-epic-3861-story-5613-${env}`

// Rebranding
export const EPIC_5209 = `release-epic-5209-company-name-and-logo-updates-${env}`

export const EPIC_4210_STORY_2478 = `release-epic-4210-story-2478-${env}`
export const OPERATIONAL_HUBSPOT_NEWSLETTER_SUBSCRIBE = `operational-hubspot-newsletter-subscribe-${env}`

//Student Enhancements
export const EPIC_2410_BUG_5257 = `release-epic-2410-bug-5257-${env}`

//Homeroom bugs
export const EPIC_3725_BUG_4305 = `release-epic-3725-bug-4305-${env}`
export const EPIC_3725_BUG_5410 = `release-epic-3725-bug-5410-${env}`

//Admin Dashboard
export const EPIC_2966_BUG_3909 = `release-epic-2966-bug-3909-${env}`

//Participation tracking
export const EPIC_5557 = `release-epic-5557-${env}`
export const EPIC_5738 = `release-epic-5738-${env}`
export const EPIC_5738_STORY_5959 = `release-epic-5738-story-5959-${env}`
export const EPIC_5738_STORY_6047 = `release-epic-5738-story-6047-${env}`
export const OPERATIONAL_SEND_SMS = `operational-send-sms-${env}`
export const OPERATIONAL_LEARNING_LOG_VALIDATE_FULL_PARTICIPATION_BEFORE_SUBMIT = `operational-learning-log-validate-full-participation-before-submit-${env}`

// Homeroom Enhancements
export const EPIC_121_BUG_5161 = `release-epic-121-story-5161-${env}`
export const EPIC_121_BUG_5473 = `release-epic-121-story-5473-${env}`
export const EPIC_121_BUG_5226 = `release-epic-121-story-5226-${env}`
export const EPIC_121_STORY_3546 = `release-epic-121-story-3546-${env}`
export const EPIC_121_STORY_5583 = `release-epic-121-story-5583-${env}`
export const EPIC_121_STORY_5886 = `release-epic-121-story-5886-${env}`
export const EPIC_121_STORY_5898 = `release-epic-121-story-5898-${env}`
export const EPIC_121_STORY_5817 = `release-epic-121-story-5817-${env}`
export const EPIC_121_STORY_5943 = `release-epic-121-story-5943-${env}`

// Design Enhancements/Fixes
export const EPIC_3323_STORY_5559 = `release-epic-3323-story-5559-${env}`

// Student notes on learning logs
export const EPIC_4162_STORY_5469 = `release-epic-4162-story-5469-${env}`
export const EPIC_4162_STORY_5536 = `release-epic-4162-story-5536-${env}`
export const EPIC_4162_BUG_5548 = `release-epic-4162-bug-5548-${env}`
export const EPIC_4162_STORY_5598 = `release-epic-4162-story-5598-${env}`
export const EPIC_4162_STORY_5047 = `release-epic-4162-story-5047-${env}`

//Opt Out Form on demand
export const EPIC_3346_STORY_4707 = `release-epic-3346-story-4707-${env}`

// Enrollment Questions
export const EPIC_5532 = `release-epic-5532-${env}`

export const EPIC_5481_STORY_5497 = `release-epic-5481-story-5497-${env}`
export const EPIC_121_STORY_5578 = `release-epic-121-story-5578-${env}`
export const EPIC_121_STORY_5616 = `release-epic-121-story-5616-${env}`

// Intervention Tracking & Reporting
export const EPIC_6048 = `release-epic-6048-${env}`

//Enrollment Enhancements
export const EPIC_3322_STORY_5507 = `release-epic-3322-story-5507-${env}`
export const EPIC_3322_STORY_5834 = `release-epic-3322-story-5834-${env}`
export const EPIC_3322_STORY_5937 = `release-epic-3322-story-5937-${env}`
export const EPIC_3322_BUG_5938 = `release-epic-3322-bug-5938-${env}`
export const EPIC_3322_STORY_5920 = `release-epic-3322-story-5920-${env}`

//Admin's Site-Management - Years - failed delete message
export const EPIC_2966_BUG_4886 = `release-epic-2966-bug-4886-${env}`

//Email Records rework
export const EPIC_2966_STORY_5840 = `release-epic-2966-story-5840-${env}`

//Email Records Enhancements
export const EPIC_4335_STORY_4347 = `release-epic-4335-story-4347-${env}`
export const EPIC_4335_STORY_5566 = `release-epic-4335-story-5566-${env}`
export const EPIC_4335_BUG_5752 = `release-epic-4335-bug-5752-${env}`

//Inactive status pop-up
export const EPIC_3322_STORY_5535 = `release-epic-3322-story-5535-${env}`

//Reimbursement Requests Table
export const EPIC_5134_BUG_5671 = `release-epic-5134-bug-5671-${env}`
export const EPIC_121_STORY_5626 = `release-epic-121-story-5626-${env}`
export const EPIC_4861_STORY_5576 = `release-epic-4861-story-5576-${env}`

// hyperwallet texts
export const EPIC_4547_TASK_5718 = `release-epic-4547-task-5718-${env}`

//Schedule Management Enhancements
export const EPIC_3862_STORY_5637 = `release-epic-3862-story-5637-${env}`
export const EPIC_3862_STORY_5233 = `release-epic-3862-story-5233-${env}`
export const EPIC_3862_STORY_5797 = `release-epic-3862-story-5797-${env}`
export const EPIC_3862_STORY_5785 = `release-epic-3862-story-5785-${env}`
export const EPIC_3862_STORY_5919 = `release-epic-3862-story-5919-${env}`
export const EPIC_3862_STORY_5961 = `release-epic-3862-story-5961-${env}`

//Withdrawals
export const EPIC_2510_BUG_5737 = `release-epic-2510-bug-5737-${env}`

//Withdrawal tooltip
export const EPIC_2510_STORY_5661 = `release-epic-2510-story-5661-${env}`

//Generate withdraw form
export const EPIC_2510_STORY_3149 = `release-epic-2510-story-3149-${env}`

export const EPIC_5400_STORY_5402 = `release-epic-5400-story-5402-${env}`
export const EPIC_121_TASK_5760 = `release-epic-121-task-5760-${env}`
export const EPIC_121_TASK_5763 = `release-epic-121-task-5763-${env}`
export const EPIC_121_STORY_5816 = `release-epic-121-story-5816-${env}`
export const EPIC_121_STORY_5821 = `release-epic-121-story-5821-${env}`
export const EPIC_667_BUG_3402 = `release-epic-667-bug-3402-${env}`

//calendar enhancements
export const EPIC_5542_STORY_5770 = `release-epic-5542-story-5770-${env}`
export const EPIC_5542_BUG_6038 = `release-epic-5542-bug-6038-${env}`

// refactor questionItem
export const EPIC_5603_TASK_5604 = `release-epic-5603-task-5604-${env}`
export const OPERATIONAL_VERBOSITY_5604 = `operational-log-verbosity-story-5604-${env}`
export const EPIC_3861_STORY_5769 = `release-epic-3861-story-5769-${env}`

// Second Semester Schedule Reports
export const EPIC_5702_TASK_5743 = `release-epic-5702-task-5743-${env}`
export const EPIC_5702_TASK_5744 = `release-epic-5702-task-5744-${env}`
export const EPIC_5702_TASK_5745 = `release-epic-5702-task-5745-${env}`

// Search Bar rework
export const EPIC_275_STORY_5811 = `release-epic-275-story-5811-${env}`
export const EPIC_275_STORY_5924 = `release-epic-275-story-5924-${env}`
export const EPIC_275_STORY_6022 = `release-epic-275-story-6022-${env}`
export const EPIC_275_TASK_6023 = `release-epic-275-task-6023-${env}`
export const EPIC_275_STORY_5988 = `release-epic-275-story-5988-${env}`

export const EPIC_5702_BUG_5891 = `release-epic-5702-bug-5891-${env}`

// 24-25 RR / DO / DD Bugs
export const EPIC_3525_BUG_5804 = `release-epic-3525-bug-5804-${env}`
export const EPIC_2510_BUG_5706 = `release-epic-2510-story-5706-${env}`

// Rest API Calls
export const EPIC_3303_STORY_3937 = `release-epic-3303-bug-3937-${env}`

// Notifications Settings
export const EPIC_275_STORY_6005 = `release-epic-275-story-6005-${env}`
export const OPERATIONAL_OBSERVER_NOTIFICATION_SETTINGS_MENU = `operational-observer-menu-notification-settings-${env}`
